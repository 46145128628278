import React from 'react';
import sortBy from 'lodash/sortBy';
import { Helmet } from 'react-helmet';
import { Link as ReactLink } from 'gatsby';
import SummarySrc from '../../components/Summary';
import styled, { css } from 'styled-components';
import { color } from '../../css/css.settings.color';
import { vw } from '../../css/css.mixins.media';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Template from '../../templates';

const Header = styled.h1`
	color: ${color.textDarkPrimary};
	margin-top: 0;
	margin-bottom: 0;
`;

const Summary = styled(SummarySrc)`
  color: ${color.textDarkSecondary};
  text-decoration: none !important;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${vw.lv10`
    max-width: 432px;
    flex-grow: 1;
    padding-right: 24px;
    padding-left: 24px;
  `};
`;

const FeatureImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	${vw.lv10`
    flex-grow: 1;
    width: 432px;
  `};
`;

const FeatureImage = styled.img`
	display: block;
	height: auto;
	width: auto;
	margin-bottom: 16px;
	max-height: 300px;

	${vw.lv10`
    margin-top: 0;
    max-height: 450px;
    max-width: 432px;
    margin-bottom: 0;
  `};
`;

const Link = styled(ReactLink)`
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 30px;
  padding: 1em 2em 2em 2em;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 17px;
  max-width: 90vw;

  &:hover,
  &:active,
  &:focus {
    color: ${color.brandPrimary};
    text-decoration: underline;
  }

  &,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:after {
    position: absolute;
    border-radius: 17px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: opacity 0.18s;
    opacity: 1;
    box-shadow: 0 3px 13px rgba(0,0,0,0.15);
  }

  ${vw.lv10`
    flex-direction: row;
    width: 960px;
    margin-bottom: 30px;
    padding: 2em;
    margin-left: 1em;
    margin-right: 1em;
    max-width: 960px;

    &:after {
      position: absolute;
      border-radius: 17px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      transition: opacity 0.18s;
      opacity: 0;
      box-shadow: 0 3px 13px rgba(0,0,0,0.15);
    }

    &:hover:after,
    &:focus:after {
      opacity: 1;
    }

    &:hover ${Header},
    &:active ${Header},
    &:focus ${Header} {
      color: ${color.brandPrimary};
      text-decoration: underline;
    }

    &:hover ${Summary}  {
      color: ${color.textDarkSecondary};
    }
  `}

  ${(props) =>
		props.invertLayout &&
		css`
			${vw.lv10`
      flex-direction: row-reverse;
      & ${TextWrapper} {
        padding-right: 24px;
        padding-left: 24px;
      }
    `};
		`}
`;

const LinksSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

class BlogIndex extends React.Component {
	render() {
		const pageLinks = [];

		// Sort pages.
		const { edges } = this.props.data.allMarkdownRemark;
		const { site: { siteMetadata: { blogTitle } } } = this.props.data;
		const sortedPages = sortBy(edges, (edge) => edge.node.date);

		sortedPages.forEach(({ node: page }) => {
			const title = page.frontmatter.title || page.frontmatter.path;
			const featureImage = page.frontmatter.featureImage;
			const invertLayout = page.frontmatter.invertLayout;
			const layout = page.frontmatter.layout;
			const path = page.frontmatter.path;

			if (title && featureImage && layout === 'portfolio') {
				pageLinks.push(
					<Link key={path} to={path} invertLayout={invertLayout}>
						<TextWrapper>
							<Header>{title}</Header>

							<Summary body={page.excerpt} />
						</TextWrapper>
						<FeatureImageWrapper>{featureImage && <FeatureImage src={featureImage} />}</FeatureImageWrapper>
					</Link>
				);
			}
		});

		return (
			<Template {...this.props}>
				<Helmet>
					<title>{blogTitle}</title>
				</Helmet>
				<LinksSection>{pageLinks}</LinksSection>
			</Template>
		);
	}
}

BlogIndex.propTypes = {
	route: PropTypes.object
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { fileAbsolutePath: { regex: "/src/contents/portfolio/" } }
		) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date
						layout
						title
						featureImage
						invertLayout
						path
					}
				}
			}
		}
		site {
			siteMetadata {
				title
				description
				authorName
				subTitle
				authorBio
				gaCode
				blogTitle
			}
		}
	}
`;
